<template>
  <div style="margin: 10px;">
    <b-row>
      <b-col sm="12" lg="1"></b-col>
      <b-col sm="12" lg="8">
        <h3>Tablo listesi</h3>
        <h6>Sistemde olması gerekli tablolar listesi</h6>
        <h6> {{ d_sqlDatabaseList.table_order.length }} adet tablo bulunmaktadır. </h6>
        <hr>
        <b-row>
          <b-col cols="12">
            <b-form-input v-model="d_searchText" placeholder="Tabloları filtrelemek için yazınız."></b-form-input>
          </b-col>
        </b-row>
        <hr>
        <table border="1" align="left" style="width: 100%; table-layout: fixed; overflow-wrap: break-word;">
          <thead>
            <tr>
              <th>Tablo Adı</th>
              <th>Bilgi</th>
              <th>Data</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(table_name, table_name_index) in d_sqlDatabaseList.table_order">
              <tr v-if="!d_searchText || (table_name.toLocaleLowerCase().indexOf(d_searchText.toLocaleLowerCase()) !== -1)">
                <td>
                  <div style="cursor: pointer;" @click="d_tableDetails.show = true; d_tableDetails.table_name = table_name">
                    {{ table_name }}
                  </div>
                </td>
                <td> {{ d_sqlDatabaseList.tables[table_name].info }} </td>
                <td>
                  <span v-if="f_controlTableData(table_name)" style="color: green;">evet</span>
                  <span v-else style="color: red;">hayır</span>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
        <hr>
      </b-col>
      <b-col sm="12" lg="3"></b-col>
    </b-row>
    <b-modal v-if="d_tableDetails.show" v-model="d_tableDetails.show" centered class="modal-success" @ok="d_tableDetails.show = false" ok-variant="success" hide-footer size="xl">
      <b-row>
        <b-col cols="12">
          <sql-database :p_tableShowDetails="{'type': 'selected', 'table_name': d_tableDetails.table_name}"></sql-database>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" style="text-align: right;">
          <b-button variant="danger" @click="d_tableDetails.show = false">Kapat</b-button>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import {
  default as SqlDatabase
} from '@/components/widgets/SqlDatabase';
import { mapGetters } from 'vuex';
export default {
  name: 'DatabaseTableList',
  computed: mapGetters({
    // lang: 'lang'
  }),
  components: {
    SqlDatabase,
  },
  props: {},
  data () {
    return {
      d_searchText: '',
      d_tableDetails: {
        'table_name': '',
        'show': false
      },
      d_sqlDatabaseList: require('@/options/sql_database').options
    };
  },
  created: function () {},
  beforeMount () {},
  mounted: function () {},
  methods: {
    f_controlTableData: function (table_name) {
      if (table_name) {
        try {
          let table_data = require('@/tables/' + table_name).options;
          if (table_data.length > 0) {
            return true;
          }
        } catch (err) {}
      }
      return false;
    }
  },
  watch: {}
}

</script>

<style type="text/css">


</style>

